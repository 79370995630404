<template>
    <div>
        <div class="container pb-5">

            <SectionTitle title="Centro de Transferencia de Tecnología Apícola"/><br>
            
            <img class = "w-100" src="@/assets/comunicacion/centroTransferencia/image-23.jpg" alt=""><br><br>
            <div class = "row">
                <img class = "col-sm-6 w-100" src="@/assets/comunicacion/centroTransferencia/image-24.jpg" alt="">
                <img class = "col-sm-6 w-100" src="@/assets/comunicacion/centroTransferencia/image-25.jpg" alt="">
            </div>

            <p class = "mt-4">EL Centro de Transferencia de Tecnología Apícola, CTTA del Programa Moscamed cuenta con una sede en el Suroccidente ubicado en el Km 124 ruta CA-2 carretera al pacífico, Río Bravo, Suchitepéquez y otra sede en el Noroccidente ubicado en el Km 332.5 Carretera a la Mesilla, la Democracia, Huehuetenango. </p>
            <p>Entre las actividades de apoyo al sector apícola que se realizan se encuentran: capacitaciones, mejoramiento genético, cría de abejas reina, laboratorio de patología apícola, meliponarios para manejo de abejas nativas. Desde los Centros Apícolas se coordinan las actividades de asistencia técnica, monitoreo de plagas y enfermedades en apiarios que lo requieran, así como investigaciones en el tema. La asistencia técnica está enfocada a grupos organizados y fincas con apiarios en el área de influencia del Programa Moscamed. Es importante resaltar que los centros de formación apícola son de los más tecnificados a nivel de Centro América y contribuyen a la formación del recurso humano calificado en buenas prácticas apícolas.</p>
            <p class = "mb-4">Las abejas ejercen una importante función en la polinización de la vegetación silvestre y en el campo agrícola, especialmente en frutales, estos insectos están desapareciendo por el deterioro de la diversidad natural de las plantas y la reducción de la producción de plantas cultivadas, por esta y otras razones de beneficio socioeconómico, el Programa Moscamed realiza su mejor esfuerzo por proteger, incentivar y desarrollar el manejo adecuado de las abejas y que conjuntamente con el establecimiento de Áreas Libres de mosca del Mediterráneo se desarrolle la fruticultura para el consumo local y exportación.</p>

            <Images :path="path" :images="images3" size="6"/>

            <div class = "mt-5">
                <h3>CTTA SUROCCIDENTE</h3>
                <p class = "mt-4">--- Capacitaciones por módulos apícolas</p>
                <p>--- Diagnósticos apícolas </p>
                <p>--- Producción de abejas reina</p>
                <p>--- Laboratorio de patología apícola</p>
                <p>--- Asistencia técnica en apiarios de comunidades, fincas con apiario, asociaciones y cooperativas ubicados dentro del área de influencia del Programa MOSCAMED.</p>
            </div>

            <Images :path="path" :images="images1" size="4"/>

        

            <div class = "mt-5">
                <h3>CTTA NOROCCIDENTE</h3>
                <p class = "mt-4">--- Capacitaciones por módulos apícolas</p>
                <p>--- Diagnósticos apícolas </p>
                <p>--- Asistencia técnica en comunidades del Noroccidente</p>

            </div>

            <div class = "text-center mt-5 mb-5">
                <h3>IMPORTACIÓN DE ABEJAS REINA DE LABORATORIOS DE ESTADOS UNIDOS, USDA, IICA, AGEXPORT, MAGA Y MOSCAMED</h3>
            </div>

            <Images :path="path" :images="images2" size="4"/>



            

            <p class = "text-justify mt-4">Importación de abejas reina de dos razas puras, Apis mellifera caucásica y Apis mellifera cárnica y un híbrido, italiana con caucásica, para cruce y mejoramiento genético por inseminación artificial, forman parte de la II Fase del Proyecto de mejoramiento genético de abejas Apis mellifera, participan USDA, IICA, AGEXPORT, MAGA y Programa Moscamed. El beneficio para los apicultores es contar con abejas más resistentes a plagas y enfermedades, mayor producción y mejor calidad de miel, así como abejas más dóciles. Producción ideal de miel para consumo local e importación. Las abejas reina importadas provienen de tres laboratorios de mejoramiento genético: Universidad de Ohio, Universidad de Washington y  BEEWEAWER de Texas de Estados Unidos; las abejas son pie de cría manejadas en el laboratorio del Centro de Transferencia de Tecnología Apícola del Programa Moscamed. Guatemala toma la vanguardia en tecnología de producción de abejas y se constituye en el proyecto apícola más avanzado de Centro América.</p>

            <p>Las abejas ejercen una importante función en la polinización de la vegetación silvestre y en el campo agrícola, especialmente en frutales, estos insectos están desapareciendo por el deterioro de la diversidad natural de las plantas y la reducción de la producción de plantas cultivadas, por esta y otras razones de beneficio socioeconómico, el Programa Moscamed realiza su mejor esfuerzo por proteger, incentivar y desarrollar el manejo adecuado de las abejas y que conjuntamente con el establecimiento de Áreas Libres de mosca del Mediterráneo se desarrolle la fruticultura para el consumo local y exportación.</p>

            
        </div>
    </div>
</template>

<script>
import Images from "@/components/Images.vue";

export default {
    name: "Home",
    components: {
        Images,
    },

    data() {
        return {
            images1: ["image-8.jpg", "image-9.jpg","image-10.jpg","image-11.jpg","image-12.jpg","image-13.jpg"],
            images2: ["image-14.jpg", "image-15.jpg","image-16.jpg","image-17.jpg","image-18.jpg","image-19.jpg","image-20.jpg","image-21.jpg","image-22.jpg"],
            images3: ["image-4.jpg", "image-5.jpg","image-6.jpg","image-7.jpg"],

            path: "comunicacion/centroTransferencia/"
        };
    },
};
</script>
